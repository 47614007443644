<template>
  <div class="home"></div>
</template>
<script>
var ua = navigator.userAgent.toLowerCase();

export default {
  mounted() {
    if (ua.match(/iPhone/i) == "iphone") {
      //iphone
      this.$router.push({ path: "/html/app/home" });
    } else if (ua.match(/Android/i) == "android") {
      //android
      this.$router.push({ path: "/html/app/home" });
    } else {
      this.$router.push({ path: "/html/index" });
    }
  },
};
</script>